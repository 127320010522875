import React from "react";
import { Row } from "../Layouts";
import useWindowDimensions from "../../utils/layout";
interface BoxLogoHorizontalProps {
  Icon: any;
  title: string;
}

const BoxLogoHorizontal: React.FC<BoxLogoHorizontalProps> = ({
  Icon,
  title,
}) => {
  const { width: widthScreen } = useWindowDimensions()

  return (
    <Row
      style={{
        alignItems: "center",
        justifyContent: widthScreen > 550 ? 'center' : 'flex-start',
        minWidth: widthScreen > 550 ? 410 : '75vw',
        minHeight: widthScreen > 550 ? 136 : 'auto',
        borderRadius: 8,
        border: "1px solid #DEDFE4",
        padding: 22,
        gap: 20,
        background: 'white'
      }}
    >
      {Icon}
      <span
        style={{
          fontFamily: "CitrinaBold",
          fontSize: widthScreen < 550 ? 18 : 24,
          color: "#161616",
          flexWrap: "wrap",
        }}
      >
        {title}
      </span>
    </Row>
  )
};

export default BoxLogoHorizontal;
