import { Collumn } from "../Layouts";
import { colors } from "../../utils/colors";
import styles from './index.module.css'
import { ErrorMessage } from "@hookform/error-message"
import { useFormContext } from "react-hook-form";
import { FormDataFullForm } from "../../pages/FullForm";
import React from "react";
import FormComponents from "../FormComponents";

const FullFormComponents: React.FC<{ field: any, index: number }> = ({ field, index }) => {
    const {
        formState: { errors },
    } = useFormContext<FormDataFullForm>();

    const widthFormatted = field?.size ? Number(field.size.replace("%", "")) : 100;
    const width = widthFormatted === 100 ? widthFormatted : widthFormatted - 2;

    return (
        <Collumn
            key={index}
            style={{
                width: `${width}%`,
            }}
        >
            <label
                style={{
                    color: "#161616",
                    fontFamily: "InterSemiBold",
                    fontSize: 14,
                    marginBottom: 9,
                }}
            >
                {field?.title ?? ''}
                {field?.title && field.required && <span style={{ color: colors.primaryOrange }}>*</span>}
            </label>
            {field?.subTitle && <span
                style={{
                    color: "#161616",
                    fontFamily: "InterSemiBold",
                    fontSize: 12,
                    marginBottom: 9,
                }}
            >
                {field.subTitle}
            </span>}

            <FormComponents field={field} />
            <ErrorMessage
                errors={errors}
                name={field.code}
                render={({ message }) => message && <span className={styles.error}>{message}</span>}
            />
        </Collumn>
    )
}

export default FullFormComponents;