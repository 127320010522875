import { MdCheck } from "react-icons/md";
import { CenteredDiv, Collumn, Row } from "../Layouts";
import { Fragment } from "react/jsx-runtime";
import { colors } from "../../utils/colors";
import styles from './index.module.css'


const UserProgressSteps: React.FC = () => {
  const stepTitles = [
    "Form Submitted",
    "Form Analysis",
    "Hand Sign Documents",
    "Approved",
    "E-sign Contract",
    "Trainings",
    "Finished"
  ]
  const step = 0;


  return (
    <Row
      style={{
        justifyContent: "space-between",
        position: "relative",
        paddingLeft: 30,
        paddingRight: 50,
        marginTop: 73,
        marginBottom: 140
      }}
    >
      {stepTitles.map((item, index) => {
        return (
          <Fragment key={index}>
            {index !== 0 && (
              <CenteredDiv style={{ flex: 1 }}>
                <div
                  style={{
                    minWidth: 150,
                    width: "100%",
                    height: 4,
                    background: index <= step ? "#EA7100" : "#EEEEF5",
                  }}
                />
              </CenteredDiv>
            )}
            <Collumn key={index}>
              {index < step && (
                <CenteredDiv style={{ width: 21, height: 21, borderRadius: 10, backgroundColor: colors.primaryOrange }}>
                  <MdCheck size={14} color="white" />
                </CenteredDiv>
              )}
              {index === step && (
                <CenteredDiv style={{ width: 13, height: 13, borderRadius: 50, backgroundColor: ' white', border: '4px solid #ea7100' }}>
                  <MdCheck size={14} color="white" />
                </CenteredDiv>

              )}
              {index > step && (
                <div style={{ width: 21, height: 21, borderRadius: 50, backgroundColor: '#EEEEF5', }} />
              )}
              <Collumn style={{
                position: "absolute",
                top: 30,
                width: 90,
                marginLeft: -35,
                gap: 8,
              }}>
                <span
                  style={{
                    textAlign: "center",
                    fontFamily: "InterSemiBold",
                    fontSize: 14,
                    color: index <= step ? "#161616" : "#9C9DA7",
                  }}
                >
                  {item}
                </span>
                <Collumn style={{
                  alignItems:'center',
                  borderRadius: 8,
                  border: '1px solid #943d15',
                  backgroundColor: '#FEF8EB',
                  padding: 4,
                  width: 90
                }}>
                  <span className={styles.boxTitle}>Jan 31 - Feb 01</span>
                  <span className={styles.boxSubtitle}>13:41:51</span>
                </Collumn>
              </Collumn>

            </Collumn>
          </Fragment>
        );
      })}
    </Row>
  );
};

export default UserProgressSteps;
