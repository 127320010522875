"use client";
import Header from "../../components/Header";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BoxLogoVertical from "../../components/BoxLogoVertical";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { colors } from "../../utils/colors";
import BoxLogoHorizontal from "../../components/BoxLogoHorizontal";

import costBenefit from "../../assets/costBenefit.png";
import whoWeAre from "../../assets/whoWeAre.png";

import locationOn from "../../assets/locationOn.svg";
import benefits from "../../assets/benefits.svg";
import authorizedAgents from "../../assets/authorizedAgents.svg";

import light from "../../assets/light.svg";
import star from "../../assets/star.svg";
import taxes from "../../assets/taxes.svg";

import pixLogo from "../../assets/pix.svg";
import bankDeposit from "../../assets/bankDeposit.svg";
import world from "../../assets/world.svg";

import fincen from "../../assets/FINCEN.svg"
import nmls from "../../assets/NMLS.svg"

import Footer from "../../components/Footer";
import styles from "./index.module.css";
import { CenteredDiv, Collumn, Link, Row } from "../../components/Layouts";
import { useNavigate } from "react-router-dom";
import Testimonials from "../../components/Testimonials";
import useWindowDimensions from "../../utils/layout";
import { useTranslation } from "react-i18next";
import enterprise from "../../assets/enterprise.svg";
import foto from "../../assets/couple.png";
import coupleRounded from "../../assets/coupleRounded.svg";
import couple from "../../assets/couple.jpg";
import coupleHome from "../../assets/couple-home.svg";
import useScrollSnap from 'react-use-scroll-snap'
import { useEffect, useRef } from "react";


const Home = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { t } = useTranslation()

  const scrollRef = useRef<any>(null);
  useScrollSnap({ ref: scrollRef, duration: 300 });

  useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
  }, []);

  return (
    <div ref={scrollRef} style={{ flex: 1 }} >

      <Header modeDark />

      <Row
        style={{
          display: 'block',
          background:
            width <= 1400 || width > 2350
              ? 'white'
              : `url(${couple}) -400px -40px / 130% 110%`,
          backgroundPosition: 'fixed',
          justifyContent: "space-between",
          alignItems: 'center',
          minHeight: '100vh',
          width: '100vw',
        }}
      >
        <Collumn
          style={{
            justifyContent: "center",
            alignItems: width <= 1400 ? 'center' : 'flex-start',
            gap: 22,
            width: width <= 1400
              ? '100vw'
              : 600,
            height: '100vh',
            padding: width < 1400 ? '50px 10px' : '0px 72px'
          }}
        >
          <span className={styles.title} style={{
            fontSize: width <= 720
              ? 28
              : 48,
            textAlign: width <= 1400 ? 'center' : 'left'
          }}>
            {t('Home.Section1.Title')}
          </span>

          <CenteredDiv
            style={{
              width: width <= 720
                ? '80%'
                : 600,
            }}>
            <span className={styles.subTitle} style={{
              fontSize: width <= 720
                ? 21
                : 32,
              color: colors.primaryOrange,
              textAlign: width <= 1400 ? 'center' : 'left'
            }}>
              {t('Home.Section1.Subtitle')}
            </span>
          </CenteredDiv>

          {
            width <= 1400 && (
              <img src={coupleRounded} alt="couple-holding-laptop" style={{
                width: width <= 550 ? '90%' : 400,
                height: width <= 550 ? 'auto' : 400
              }} />
            )
          }

          <Link href="/short-form">
            <CenteredDiv className={styles.applyButton} style={{
              width: width <= 1400
                ? '50vw'
                : 450,
              maxWidth: width <= 1400 ? 360 : 600

            }}>
              <span className={styles.applyButtonText}>
                {t('Home.Section1.FillButton')}
              </span>
              <IoArrowForward
                size={width < 720 ? 15 : 24}
                color="white"
                style={{ marginLeft: 12 }}
              />
            </CenteredDiv>
          </Link>
        </Collumn>

        {width >= 2350 && (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', maxWidth: '60vw' }} >
            <img src={coupleHome} alt="couple-holding-laptop" style={{ height: '100vh', objectFit: 'fill' }} />
          </div>
        )}
      </Row>

      <CenteredDiv
        className={styles.containerBoxesInfo}
        style={{
          padding: width > 550 ? '30px 156px' : '45px 15px 15px 15px',
        }}
      >
        <span
          className={styles.containerBoxesInfoTitle}
          style={{
            fontFamily: "CitrinaBold",
            fontSize: width > 550 ? 48 : 28,
          }}
        >
          {t('Home.Section2.Title')}
        </span>
        <div className={styles.container3Boxes}>
          <BoxLogoVertical
            href="/find-agent"
            Icon={
              <img src={locationOn} alt="bank-deposit" />
            }
            title={t('Home.Section2.Card1.Title')}
            subtitle={t('Home.Section2.Card1.Text')}
          />
          <BoxLogoVertical
            href="/"
            Icon={
              <img src={authorizedAgents} alt="bank-deposit" />
            }
            title={t('Home.Section2.Card2.Title')}
            subtitle={t('Home.Section2.Card2.Text')}
          />
          <BoxLogoVertical
            href="/"
            Icon={
              <img src={benefits} alt="bank-deposit" />
            }
            title={t('Home.Section2.Card3.Title')}
            subtitle={t('Home.Section2.Card3.Text')}
          />
        </div>
      </CenteredDiv>

      <CenteredDiv
        style={{
          padding: width > 550 ? "96px 156px" : '68px 40px 50px 40px',
          boxSizing: 'border-box',
          backgroundColor: "#FFC799",
          minHeight: "100vh",
          height: width < 550 ? '100vh' : 'auto',
          flexWrap: "wrap",
        }}
      >
        {width > 550 ?
          <CenteredDiv style={{
            gap: 120,
          }}>
            <img
              alt="who-we-are"
              src={whoWeAre}
              style={{
                width: 456,
                height: 456
              }}
            />
            <Collumn style={{ width: 457, alignItems: 'flex-start' }}>
              <span
                style={{
                  color: colors.neutralGray04,
                  fontFamily: "CitrinaBold",
                  fontSize: 48,
                  marginBottom: 24,
                  textAlign: "left",
                }}
              >
                {t('Home.Section3.Title')}
              </span>

              <Row style={{ alignItems: 'center', marginBottom: 11 }}>
                <img src={light} alt="light-bulb" color={colors.primaryOrange} style={{ marginRight: 16, width: 32, height: 32 }} />
                <span style={{
                  color: colors.neutralGray04,
                  fontSize: 32,
                  fontFamily: "CitrinaBold"
                }}>
                  {t("Home.Section3.Paragraph1.Title")}
                </span>
              </Row>
              <span
                style={{
                  fontFamily: "Inter",
                  fontSize: 20,
                  color: colors.neutralGray05,
                  lineHeight: "150%",
                  textAlign: 'left',
                  marginBottom: 14
                }}
              >
                {t('Home.Section3.Paragraph1.Text')}
              </span>

              <Row style={{ alignItems: 'center', marginBottom: 11 }}>
                <img src={star} alt="star" color={colors.primaryOrange} style={{ marginRight: 16, width: 32, height: 32 }} />
                <span style={{
                  color: colors.neutralGray04,
                  fontSize: 32,
                  fontFamily: "CitrinaBold"
                }}>
                  {t("Home.Section3.Paragraph2.Title")}
                </span>
              </Row>
              <span
                style={{
                  fontFamily: "Inter",
                  fontSize: 20,
                  color: colors.neutralGray05,
                  lineHeight: "150%",
                  textAlign: "left",
                }}
              >
                {t('Home.Section3.Paragraph2.Text')}
              </span>
              <button
                className={styles.costBenefitApplyButton}
                style={{ marginTop: 40 }}
                onClick={() => navigate("/short-form")}
              >
                {t('Home.Section4.ApplyText')}
                <IoArrowForward size={24} color="white" style={{ marginLeft: 8 }} />
              </button>
            </Collumn>
          </CenteredDiv>
          :
          <CenteredDiv style={{
            flexDirection: 'column',
            gap: 32,
          }}>
            <img
              alt="who-we-are"
              src={whoWeAre}
              style={{
                width: '80vw',
                height: '80vw'
              }}
            />
            <Collumn style={{ width: ' 90vw', alignItems: 'center' }}>
              <span
                style={{
                  color: colors.neutralGray04,
                  fontFamily: "CitrinaBold",
                  fontSize: 32,
                  marginBottom: 24,
                  textAlign: 'center',
                }}
              >
                {t('Home.Section3.Title')}
              </span>

              <span
                style={{
                  fontFamily: "Inter",
                  fontSize: 18,
                  color: colors.neutralGray05,
                  lineHeight: "150%",
                  textAlign: width > 550 ? "left" : 'center',
                  marginBottom: 14
                }}
              >
                {t('Home.Section3.Paragraph1.Text') + ' ' + t('Home.Section3.Paragraph2.Text')}
              </span>

              <button
                className={styles.costBenefitApplyButton}
                onClick={() => navigate("/short-form")}
              >
                {t('Home.Section4.ApplyText')}
                <IoArrowForward size={24} color="white" style={{ marginLeft: 8 }} />
              </button>
            </Collumn>
          </CenteredDiv>}
      </CenteredDiv>

      <CenteredDiv
        style={{
          gap: 120,
          padding: width > 550 ? "96px 156px" : 50,
          boxSizing: 'border-box',
          minHeight: "100vh",
          justifyContent: width > 550 ? 'space-around' : 'center',
          flexWrap: 'wrap'
        }}
      >
        {width > 550 ?
          <>
            <Collumn style={{
              width: 457,
              alignItems: 'flex-start'
            }}>
              <span
                style={{
                  fontFamily: "CitrinaBold",
                  color: colors.neutralGray04,
                  fontSize: 48,
                  marginBottom: 22,
                  textAlign: "start",
                }}
              >
                {t('Home.Section4.Title')}
              </span>

              <Row style={{ alignItems: 'center', marginBottom: 11 }}>
                <img src={taxes} alt="taxes-icon" color={colors.primaryOrange} style={{ marginRight: 16, width: 32, height: 32 }} />
                <span style={{
                  color: colors.neutralGray04,
                  fontSize: 32,
                  fontFamily: "CitrinaBold"
                }}>
                  {t("Home.Section4.Paragraph1.Title")}
                </span>
              </Row>
              <span
                style={{
                  fontFamily: "Inter",
                  fontSize: 20,
                  color: colors.neutralGray05,
                  lineHeight: "150%",
                  textAlign: "left",
                  marginBottom: 14
                }}
              >
                {t('Home.Section4.Paragraph1.Text')}
              </span>

              <Row style={{ alignItems: 'center', marginBottom: 11 }}>
                <img src={enterprise} alt="ellipsis" style={{ marginRight: 16 }} />
                <span style={{
                  color: colors.neutralGray04,
                  fontSize: 32,
                  fontFamily: "CitrinaBold"
                }}>
                  {t("Home.Section4.Paragraph2.Title")}
                </span>
              </Row>
              <span
                style={{
                  fontFamily: "Inter",
                  fontSize: 20,
                  color: colors.neutralGray05,
                  lineHeight: "150%",
                  textAlign: width > 550 ? "left" : 'center',
                }}
              >
                {t('Home.Section4.Paragraph2.Text')}
              </span>

              <button
                className={styles.costBenefitApplyButton}
                style={{ marginTop: 40 }}
                onClick={() => navigate("/short-form")}
              >
                {t('Home.Section4.ApplyText')}
                <IoArrowForward size={24} color="white" style={{ marginLeft: 8 }} />
              </button>
            </Collumn>

            <img
              src={costBenefit}
              alt="cost-benefit"
              style={{
                width: width > 550 ? 456 : '80vw',
                height: width > 550 ? 456 : '80vw',
              }}
            />
          </>
          :
          <CenteredDiv style={{
            flexDirection: 'column',
            marginTop: 42,
            gap: 32
          }}>
            <img
              src={costBenefit}
              alt="cost-benefit"
              style={{
                width: width > 550 ? 456 : '80vw',
                height: width > 550 ? 456 : '80vw',
              }}
            />

            <Collumn style={{
              width: ' 90vw',
              alignItems: 'center',
            }}>
              <span
                style={{
                  fontFamily: "CitrinaBold",
                  color: colors.neutralGray04,
                  fontSize: width > 550 ? 48 : 32,
                  marginBottom: 12,
                  textAlign: width > 550 ? "start" : 'center',
                }}
              >
                {t('Home.Section4.Title')}
              </span>
              <span
                style={{
                  fontFamily: "Inter",
                  fontSize: 18,
                  color: colors.neutralGray05,
                  lineHeight: "150%",
                  textAlign: width > 550 ? "left" : 'center',
                  marginBottom: 14
                }}
              >
                {t('Home.Section4.Paragraph1.Text') + '. ' + t('Home.Section4.Paragraph2.Text')}
              </span>
            </Collumn>

            <button
              className={styles.costBenefitApplyButton}
              onClick={() => navigate("/short-form")}
            >
              {t('Home.Section4.ApplyText')}
              <IoArrowForward size={24} color="white" style={{ marginLeft: 8 }} />
            </button>
          </CenteredDiv>
        }
      </CenteredDiv>

      <CenteredDiv
        style={{
          padding: width > 550 ? "96px 156px" : 50,
          boxSizing: 'border-box',
          flexDirection: 'column',
          backgroundColor: "#F5F6FA",
          minHeight: "100vh",
          gap: 23
        }}
      >
        <span
          style={{
            fontFamily: "CitrinaBold",
            fontSize: width > 550 ? 48 : 32,
            color: colors.neutralGray04,
            textAlign: "center",
            marginBottom: 23
          }}
        >
          {t('Home.Section5.Title')}
        </span>
        <Collumn style={{ gap: 22 }}>
          <div className={styles.containerLeaderSolution}>
            <BoxLogoHorizontal
              Icon={<img src={bankDeposit} alt="bank-deposit" />}
              title={t('Home.Section5.Card1')}
            />
            <BoxLogoHorizontal
              Icon={<img src={pixLogo} alt="pix" />}
              title={t('Home.Section5.Card2')}
            />
          </div>
          <BoxLogoHorizontal
            Icon={<img src={world} alt="world" />}
            title={t('Home.Section5.Card4')}
          />
          {width > 550 && <CenteredDiv>
            <button
              className={styles.costBenefitApplyButton}
              onClick={() => navigate("/short-form")}
            >
              {t('Home.Section4.ApplyText')}
              <IoArrowForward size={24} color="white" style={{ marginLeft: 8 }} />
            </button>
          </CenteredDiv>}
        </Collumn>
      </CenteredDiv>

      <CenteredDiv
        className={styles.testimonials}
      >
        <span
          style={{
            fontFamily: "CitrinaBold",
            fontSize: width > 550 ? 48 : 32,
            marginBottom: 32,
            lineHeight: "118%",
            color: colors.neutralGray04,
          }}
        >
          {t('Home.Section6.Title')}
        </span>

        <Collumn style={{ position: "relative" }}>
          <Carousel
            autoPlay
            infiniteLoop
            width={'100vw'}
            axis="horizontal"
            interval={5000}
            transitionTime={2000}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            centerMode={width > 1440}
            centerSlidePercentage={65}
            swipeable
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <CenteredDiv
                  style={{
                    position: "absolute",
                    flex: 1,
                    bottom: 30,
                    left: "calc(50% - 30px)",
                    border: "1px solid #EBEBEB",
                    borderRadius: 66,
                    width: 45,
                    height: 40,
                    cursor: "pointer",
                    zIndex: 20,
                  }}
                >
                  <IoArrowBack
                    onClick={onClickHandler}
                    size={24}
                    color={colors.primaryOrange}
                  />
                </CenteredDiv>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <CenteredDiv
                  style={{
                    position: "absolute",
                    flex: 1,
                    bottom: 30,
                    left: "calc(50% + 30px)",
                    border: "1px solid #EBEBEB",
                    borderRadius: 66,
                    width: 45,
                    height: 40,
                    cursor: "pointer",
                  }}
                >
                  <IoArrowForward
                    onClick={onClickHandler}
                    size={24}
                    color={colors.primaryOrange}
                  />
                </CenteredDiv>
              )
            }
          >
            <Testimonials
              text={t('Home.Section6.Testimonial1.Text')}
              name={t('Home.Section6.Testimonial1.PersonNameAndCompany')}
              position={t('Home.Section6.Testimonial1.PersonCity')}
            />
            <Testimonials
              text={t('Home.Section6.Testimonial2.Text')}
              name={t('Home.Section6.Testimonial2.PersonNameAndCompany')}
              position={t('Home.Section6.Testimonial2.PersonCity')}
            />
            <Testimonials
              text={t('Home.Section6.Testimonial3.Text')}
              name={t('Home.Section6.Testimonial3.PersonNameAndCompany')}
              position={t('Home.Section6.Testimonial3.PersonCity')}
            />

          </Carousel>
        </Collumn>
      </CenteredDiv>

      <CenteredDiv
        className={styles.compliance}
        style={{
          padding: width > 550 ? '56px 130px' : '0px'
        }}
      >

        <Collumn
          id="compliance"
        >
          <span
            style={{
              fontFamily: "CitrinaBold",
              color: colors.primaryOrange,
              fontSize: width > 550 ? 48 : 32,
              marginBottom: 12,
              textAlign: width > 550 ? 'left' : 'center'
            }}
          >
            {t("Home.Section7.Title")}
          </span>
          <span
            style={{
              fontFamily: "CitrinaBold",
              fontSize: width > 550 ? 32 : 18,
              color: '#3E322E',
              lineHeight: "124%",
              textAlign: width > 550 ? "start" : 'center',
            }}
          >
            {t("Home.Section7.TextFirstLine")}
            <br />
            {t("Home.Section7.TextSecondLine")}
            <br />
            {t("Home.Section7.TextThirdLine")}
            <br />
            {t("Home.Section7.TextFourthLine")}
            <br />
            {t("Home.Section7.TextFifthLine")}
          </span>
        </Collumn>
        <img src={fincen} alt="fincen" />
        <img src={nmls} alt="nmls" />
      </CenteredDiv>

      <Footer />

    </div >
  );
};

export default Home;
