import { useFormContext } from "react-hook-form";
import styles from './index.module.css'
import { CenteredDiv, Collumn, Row } from "../Layouts";
import { MdCheck } from "react-icons/md";
import FileInput from "../FileInput";
import DateInput from "../DateInput";
import { ErrorMessage } from "@hookform/error-message";
import { colors } from "../../utils/colors";
import { MenuItem, Select } from "@mui/material";
import {
    CountrySelect,
    StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import './index.css'
import { useState } from "react";

const FormComponents = ({ field }: { field: any }) => {
    const {
        register,
        setValue,
        formState: { errors },
        watch
    } = useFormContext();

    const codesCSC = ['contactCountry', 'contactState']
    const [countryid, setCountryid] = useState(0);
    const [stateid, setstateid] = useState(0);

    const renderField = (field: any) => {
        switch (field.elementType) {
            case 'INPUT_TEXT': return (
                <input
                    {...register(field.code, { required: field.required ? 'missing Field' : undefined, })}
                    className={styles.input}
                    placeholder="Type here..."
                />
            )
            case 'INPUT_NUMBER': return (
                <input
                    {...register(field.code, { required: field.required ? 'missing Field' : undefined, })}
                    type="number"
                    className={styles.input}
                    placeholder="Type here..."
                />
            )
            case 'RADIO_BUTTON_GROUP': {
                const buttonSelected = watch(field.code);

                return (
                    <Row
                        style={{
                            minWidth: 0,
                            gap: 22,
                        }}
                    >
                        {field?.options && field.options.map((item: any) => {
                            if (field.layoutType === 'BUTTON')
                                return (
                                    <CenteredDiv
                                        className={styles.button}
                                        onClick={() => {
                                            setValue(field.code, item.order)
                                        }}
                                        style={{
                                            backgroundColor: buttonSelected === item.order ? colors.primaryOrange : "white",
                                            maxWidth: 200
                                        }}>
                                        <span style={{
                                            color: buttonSelected === item.order ? "white" : colors.primaryOrange,
                                        }}>
                                            {item.value}
                                        </span>
                                    </CenteredDiv>
                                )

                            if (field.layoutType === 'DEFAULT')
                                return (
                                    <label className={styles.radio}>
                                        <input
                                            type="radio"
                                            {...register(field.code)}
                                            value={item.value}
                                        />
                                        <CenteredDiv className={styles.checkmark}>
                                            <MdCheck />
                                        </CenteredDiv>
                                        <CenteredDiv style={{ height: 28 }}>
                                            {item.code}
                                        </CenteredDiv>
                                    </label>
                                )

                            return null;
                        })}
                    </Row>
                )
            }
            case 'INPUT_FILE': return (
                <FileInput registerName={field.code} isRequired={field.required} />
            )
            case 'INPUT_DATE': return (
                <DateInput registerName={field.code} isRequired={field.required} />
            )
            case 'INPUT_SELECT': return (
                <Select {...register(field.code, { required: field.required ? 'missing Field' : undefined })}
                    style={{ boxSizing: 'content-box', fontSize: 14, color: '#9ea1b1', fontFamily: 'Inter', borderRadius:8, height: 34 }} className={styles.input}
                    // defaultValue={field?.options[0].value}
                    sx={{
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #ea7100',
                        },
                        '&.MuiInputBase-root': {
                            border: '1px solid #ebebeb',
                        },
                        '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                        }
                    }}>
                    {
                        field?.options && field?.options?.map((item: any) => (
                            <MenuItem value={item.value}>{item.name}</MenuItem>
                        ))
                    }
                </Select >
            )
            case 'STATIC_TEXT': return (
                <>
                    {
                        field?.paragraphs && field.paragraphs.map((item: any) => {
                            return <span className={styles.subtitle}>{item.value}</span>
                        })
                    }
                </>
            )
            case 'TEXTAREA': return (
                <textarea
                    {...register(field.code, { required: field.required ? 'missing Field' : undefined, })}
                    className={styles.input}
                    placeholder="Type here..."
                    rows={5}
                    style={{ minHeight: 100 }}
                />
            )
            case 'FIELDS_GROUP_LIST': return (
                <Collumn style={{ gap: 22 }}>
                    {field?.subFields?.map((subField: any) => {

                        const widthFormatted = subField?.size ? Number(subField.size.replace("%", "")) : 100;;
                        const widthh = widthFormatted === 100 ? widthFormatted : widthFormatted - 1.5;
                        return (
                            <Collumn
                                style={{
                                    width: `${widthh}%`,
                                }}
                            >
                                <label
                                    style={{
                                        color: "#161616",
                                        fontFamily: "InterSemiBold",
                                        fontSize: 14,
                                        marginBottom: 9,
                                    }}
                                >
                                    {subField.title ?? ''}
                                    {subField.required && <span style={{ color: colors.primaryOrange }}>*</span>}
                                </label>
                                {subField.subTitle && <span
                                    style={{
                                        color: "#161616",
                                        fontFamily: "InterSemiBold",
                                        fontSize: 12,
                                        marginBottom: 9,
                                    }}
                                >
                                    {subField.subTitle}
                                </span>}

                                {
                                    renderField(subField)
                                }

                                <ErrorMessage
                                    errors={errors}
                                    name={field.code}
                                    render={({ message }) => message && <span className={styles.error}>{message}</span>}
                                />
                            </Collumn>
                        )
                    })}
                </Collumn >
            )
            default: return null
        }
    }

    return <>
        {
            renderField(field)
        }
    </>

}

export default FormComponents