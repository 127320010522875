import { apiClient } from "../apiClients"

export const login = async (body: any) => {
    return apiClient.post(`/authorization/login`, body)
        .then((result) => result)
        .catch((e) => console.log(e))
}

export const refreshToken = async () => {
    return apiClient.post(`/authorization/login`)
        .then((result) => result)
        .catch((e) => console.log(e))
}

export const emailEncrypted = async (body: any) => {
    return apiClient.post(`/authorization/email-confirmed-encrypted`, body)
        .then((result) => result.data
        )
        .catch((e) => console.log(e))
}

export const sendSms = async (body: any) => {
    return apiClient.post(`/authorization/send-sms`, body)
        .then((result) => result.data
        )
        .catch((e) => console.log(e))
}