"use client";
import { useFormContext } from "react-hook-form";
import { CenteredDiv, Collumn, Row } from "../Layouts";
import styles from "./index.module.css";
import { colors } from "../../utils/colors";
import { IRegisterFormData } from "../RegisterForm";
import { Checkbox } from "@mui/material";

const RegisterFormStep1: React.FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IRegisterFormData>();

  return (
    <Collumn style={{ flex: 1, gap: 22, marginBottom: 32,}}>
      <Collumn
        style={{
          width: "100%",
        }}
      >
        <label
          style={{
            color: "#161616",
            fontFamily: "InterSemiBold",
            fontSize: 14,
            marginBottom: 9,
          }}
        >
          Full Name
          <span style={{ color: colors.primaryOrange }}>*</span>
        </label>

        <input
          {...register("name")}
          className={styles.input}
        />
        <span className={styles.error}>{errors?.name?.message ?? ""}</span>
      </Collumn>

      <Collumn
        style={{
          width: "100%",
        }}
      >
        <label
          style={{
            color: "#161616",
            fontFamily: "InterSemiBold",
            fontSize: 14,
            marginBottom: 9,
          }}
        >
          E-mail
          <span style={{ color: colors.primaryOrange }}>*</span>
        </label>

        <input
          {...register("email")}
          type="email"
          disabled
          className={styles.input}
        />
        <span className={styles.error}>{errors?.email?.message ?? ""}</span>
      </Collumn>

      <Collumn
        style={{
          width: "100%",
        }}
      >
        <label
          style={{
            color: "#161616",
            fontFamily: "InterSemiBold",
            fontSize: 14,
            marginBottom: 9,
          }}
        >
          Phone
          <span style={{ color: colors.primaryOrange }}>*</span>
        </label>

        <input
          {...register("phone")}
          className={styles.input}
        />
        <span className={styles.error}>{errors?.phone?.message ?? ""}</span>
      </Collumn>

      <Row style={{ alignItems: "flex-start" }}>
        <CenteredDiv>
          <Checkbox
            {...register("lgpdAuthorized")}
            sx={{
              color: "#f0f0f0",
              borderRadius: 5,
              "&.Mui-checked": {
                color: colors.primaryOrange,
              },
            }}
          />
        </CenteredDiv>

        <span
          style={{
            color: colors.primaryOrange,
            fontFamily: "InterSemiBold",
            marginTop: 2,
          }}
        >
          *
        </span>
        <span className={styles.authorizationText}>
          I authorize Inter to process my personal data to send communications about products and services and I also agree with the{' '}
          <span
            style={{ color: colors.primaryOrange, fontFamily: "InterSemiBold" }}
          >
            privacy policy.
          </span>
        </span>
      </Row>
    </Collumn>
  );
};

export default RegisterFormStep1;
