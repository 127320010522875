import { CenteredDiv, Row } from "../../components/Layouts";
import logo from "../../assets/logo.svg";
import styles from "./index.module.css";
import React, { useState } from "react";
import useWindowDimensions from "../../utils/layout";
import carousel01 from "../../assets/carousel01.jpg";
import { IoArrowForward, IoCheckmarkCircleOutline, IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const RegisterSuccess: React.FC = () => {
  const { width } = useWindowDimensions()
  const [greenBoxOpen, setGreenBoxOpen] = useState(true);
  const navigate = useNavigate()

  return width >= 900 ? (
    <Row style={{ flex: 1 }}>
      <div
        style={{
          width: "50%",
          height: "100vh",
          background: `url(${carousel01}) lightgray 0px 0px / 100% 100% no-repeat`
        }}
      />
      <CenteredDiv
        style={{
          width: "50%",
          gap: 32,
          flexDirection: "column",
          padding: "0px 52px",
          boxSizing: "border-box",
        }}
      >
        <img src={logo} alt="logo" width={300} />
        <span className={styles.title}>Cadastro</span>
        {greenBoxOpen && <Row className={styles.greenBox}>
          <IoCheckmarkCircleOutline size={24} color="#008145" />
          <span>
            Sua conta foi criada com sucesso. Agora você já pode fazer o login!
          </span>
          <IoCloseOutline
            size={24}
            color="#3E322E"
            onClick={() => setGreenBoxOpen(false)}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
            }} />
        </Row>}
        <button onClick={()=> navigate('/login')} className={styles.continueButton}>
          <CenteredDiv>
            Login <IoArrowForward size={20} style={{ marginLeft: 8 }} />
          </CenteredDiv>
        </button>
      </CenteredDiv>
    </Row>
  ) : (
    <CenteredDiv
      style={{
        minHeight: '100vh',
        background: `url(${carousel01}) 50% / cover no-repeat`,
        padding: 20
      }}>
      <CenteredDiv
        style={{
          width: "100%",
          gap: 32,
          flexDirection: "column",
          padding: 32,
          borderRadius: 8,
          background: 'white',
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.09)'
        }}
      >
        <img src={logo} alt="logo" width={width < 500 ? 250 : 300} />
        <span className={styles.title} style={{
          fontSize: width <= 550 ? 28 : 48
        }}>Cadastro</span>
        {greenBoxOpen && <Row className={styles.greenBox}>
          <IoCheckmarkCircleOutline size={24} color="#008145" />
          <span>
            Sua conta foi criada com sucesso. Agora você já pode fazer o login!
          </span>
          <IoCloseOutline
            size={24}
            color="#3E322E"
            onClick={() => setGreenBoxOpen(false)}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
            }}
          />
        </Row>}
      </CenteredDiv>
    </CenteredDiv>
  );
};

export default RegisterSuccess;
