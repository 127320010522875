"use client";
import logoWhite from "../../assets/logoWhite.svg";
import { colors } from "../../utils/colors";
import styles from "./index.module.css";
import { MdLogin } from "react-icons/md";
import { Collumn, Link, Row } from "../Layouts";

const HeaderForms = ({ modeDark, showOptions, hideItems }: { modeDark?: boolean, showOptions?: boolean, hideItems?: boolean }) => {

  return (
    <div className={styles.containerBox}>
      <Collumn
        className={styles.container}
        style={{
          background: colors.primaryOrange,
          zIndex: 10
        }}
      >
        <Row
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Link href={"/"}>
            <img src={logoWhite} alt="logo" width={200} height={30} />
          </Link>

          <div className={styles.containerMenuOptions}>
            {!hideItems &&
              <>
                <Link href="/">
                  <span className={styles.modalItemText} >Home</span>
                </Link>

                <Link href="/">
                  <span className={styles.modalItemText} >Agentes</span>
                </Link>

                <a
                  className={styles.loginButton}
                  href="/login"
                >
                  <span
                    className={styles.modalItemText}
                  >
                    Linda Watson
                  </span>
                  <MdLogin
                    color={'white'}
                    style={{ marginLeft: 8 }}
                    size={24}
                  />

                </a>
              </>
            }
          </div>
        </Row>
      </Collumn>
    </div>
  )

};

export default HeaderForms;
