import {
  IoLogoInstagram,
} from "react-icons/io5";
import { colors } from "../../utils/colors";
import { Collumn } from "../Layouts";
import styles from "./index.module.css";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../utils/layout";
import DropdownLanguage from "../DropdownLanguage";

const Footer = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions()

  return (
    <div
      style={{
        backgroundColor: colors.neutralGray04,
        padding: width > 550 ? "22px 72px" : 22,
      }}
    >
      <div
        style={{ marginBottom: 22 }}>
        <span
          style={{
            fontFamily: "Inter",
            fontSize: 14,
            color: "white",
          }}
        >
          <span style={{ fontFamily: "InterBold" }}>
            INTER & CO PAYMENTS INC.
          </span>
          , doing business as Inter&Co (“INTER&CO”), entidade regulada e
          autorizada a funcionar nos termos das regulamentações dos Estados Unidos
          da América (EUA) é responsável por ofertar a Inter&Co Account e por
          prestar os serviços a ela relacionados. A Inter&Co Account é um serviço
          financeiro regulado nos EUA e, portanto, obedece às leis e regulações
          deste país. A INTER & CO PAYMENTS INC., não é um banco. O BANCO INTER
          S.A., entidade brasileira devidamente autorizada a funcionar pelo Banco
          Central do Brasil, figura como intermediário. O cartão de débito da sua
          Inter&Co Account é um cartão de débito emitido pelo American State Bank,
          membro do FDIC, nos termos de uma licença da Mastercard®. Veja o
          contrato de uso de cartão para mais detalhes. Os depósitos da Inter&Co
          Account são mantidos junto ao Community Federal Savings Bank (“Banco
          Parceiro”), membro do Federal Deposit Insurance Corporation (“FDIC”). Se
          tiver alguma dúvida referente ao extrato, basta ligar para {' '}
          <span style={{ textDecoration: "underline" }}>
            1-888-305-7264
          </span>
          , ou enviar um e-mail para {' '}
          <span style={{ textDecoration: "underline" }}> help@inter.co</span>. Para
          informações adicionais sobre o Inter, acesse {' '}
          <span style={{ textDecoration: "underline" }}>
            https://ajuda.bancointer.com.br/pt-BR/
          </span>
          . Nosso setor de atendimento está disponível a qualquer hora.
        </span>
      </div>

      <div className={styles.containerColumns}>
        <Collumn style={{ gap: 13 }}>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            Escolha outro país ou região para ver
            <br />o conteúdo específico da sua localização
            <br />
          </span>
          <span
            style={{
              fontFamily: "InterBold",
              fontSize: 16,
              color: "white",
              lineHeight: "150%",
            }}
          >
            Follow us
          </span>
          <a href="/">
            <IoLogoInstagram size={24} color="white" />
          </a>
        </Collumn>

        <DropdownLanguage />

        <Collumn style={{ gap: 13 }}>
          <span
            style={{
              fontFamily: "InterBold",
              fontSize: 16,
              color: "white",
            }}
          >
            Terms and security
          </span>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            Compliance
          </span>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            Cashback Agreement
          </span>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            INTER Global Account Fees Schedule Long form
          </span>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            {t('React')}
          </span>
        </Collumn>

        <Collumn style={{ gap: 13 }}>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            Global Account Agreement
          </span>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            Privacy Policy
          </span>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            Compliance Declaration
          </span>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            Licensing by state
          </span>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            Fraud Prevention
          </span>
        </Collumn>

        <Collumn style={{ gap: 13 }}>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            Gorem
          </span>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            Gorem
          </span>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            Gorem
          </span>
          <span
            style={{
              fontFamily: "Inter",
              fontSize: 14,
              color: "white",
            }}
          >
            Gorem
          </span>
        </Collumn>
      </div>
    </div >
  )
};

export default Footer;
