import { MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { Row } from "../Layouts"
import { IoArrowDown } from "react-icons/io5"
import brazilFlag from "../../assets/brazilFlagRounded.svg";
import usaFlag from "../../assets/usaFlag.svg";
import i18n from "../../i18n";

const DropdownLanguage = ({ isFooter, modeDark }: { isFooter?: boolean, modeDark?: boolean }) => {
    return (
        <Select
            defaultValue={i18n.language}
            onChange={(event: SelectChangeEvent) => {
                i18n.changeLanguage(event.target.value)
                localStorage.setItem('lang', event.target.value)
            }}
            IconComponent={() => isFooter ? (
                <IoArrowDown size={40} color="white" />
            ) : null}
            sx={{
                boxShadow: 'none', outline: 'none', height: 35,
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                    border: 0,
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                    border: 0,
                },
                '.MuiOutlinedInput-notchedOutline': { border: 0 }
            }}
            style={{ width: 116, color: modeDark ? '#161616' : 'white', fontSize: 20, fontFamily: isFooter ? 'InterBold' : 'InterSemiBold', marginRight: 0 }}
        >
            <MenuItem value={'pt'} style={{ height: 60 }}>
                <Row style={{ alignItems: 'center', gap: 8 }}>
                    <img src={brazilFlag} alt="Brazil Flag" />
                    <p>Brasil</p>
                </Row>
            </MenuItem>

            <MenuItem value={'en'} style={{ height: 60 }}>
                <Row style={{ alignItems: 'center', gap: 8 }}>
                    <img src={usaFlag} alt="USA Flag" width={30} height={25} />
                    <p>USA</p>
                </Row>
            </MenuItem>
        </Select>
    )
}

export default DropdownLanguage