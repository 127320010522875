import axios from 'axios';
import { refreshToken } from './auth/authApi';

// Create an Axios instance with common settings
export const apiClient = axios.create({
    baseURL: 'https://interagents.api.oke.luby.me',
    headers: {
        'Content-Type': 'application/json',
    },
});

export const apiStorageClient = axios.create({
    baseURL: 'https://interagents.storage.oke.luby.me',
    headers: {
        'Content-Type': 'application/json',
    },
});

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response && error.response.status === 401) {
            try {
                const newToken = await refreshToken()
                axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`

                const originalRequest = error.config;
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                return axios(originalRequest)
            }
            catch (refreshError) {
                return Promise.reject(refreshError)
            }
        }
        return Promise.reject(error)
    }
)
