"use client";
import HeaderForms from "../../components/HeaderForms";
import { Collumn } from "../../components/Layouts";
import ShortForm from "../../components/ShortForm";
import styles from './index.module.css'

const ShortFormScreen = () => {

  return (
    <>
      <HeaderForms  hideItems/>
      <Collumn className={styles.container}>
        <div style={{ width: 743, padding: '20px 50px', borderRadius: 8, background: 'white', boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.09)' }}>
          <ShortForm />
        </div>
      </Collumn>
    </>
  );
};

export default ShortFormScreen;
