"use client";
import Steps from "../../components/Steps";
import { useState } from "react";
import { Collumn } from "../../components/Layouts";
import RegisterForm from "../../components/RegisterForm";
import HeaderForms from "../../components/HeaderForms";
import styles from './index.module.css'
import useWindowDimensions from "../../utils/layout";

const Register = () => {
  const [step, setStep] = useState(1);
  const { width } = useWindowDimensions()

  return (
    <>
      <HeaderForms hideItems />
      <Collumn className={styles.container}>
        <Collumn style={{ maxWidth: 743, alignItems: 'center', padding: width > 550 ? '20px 40px' : 20, width: width > 800 ? 743 : '80vw', borderRadius: 8, background: 'white', boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.09)' }}>
          <Steps step={step} />
          <RegisterForm step={step} setStep={setStep} />
        </Collumn>
      </Collumn>
    </>
  );
};

export default Register;
