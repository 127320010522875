import { BiRefresh } from "react-icons/bi";
import styles from "./index.module.css";

export const Loading = () => {
    return (
        <div className={styles["alert-background"]}>
            <BiRefresh size={50} color="#ea7100" />
            {/* <img src={'https://s3-alpha-sig.figma.com/img/d7d4/6e78/5c2ffc30633fb2788d1b35c0768ad0e2?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ZVgAhb5nm0LCQW19J885vOf~fHf4-s4Pvk8mUrU~4qN-8GmwkHMg4KDee4Zka-C3EkRZHvHmzzRF54UtBc0MW66CeGYzkk4~OxV1yKtE6KkvDEbTMQzaUMECaqr9DlNamxmH~-0QsGkWsYNG~sY0CcynOOPnOFEfVj6wR6EgJYLZeI0P46m~TGzq4M9obZJbl~LgC4OAYp1CIZXkYAZBFMy9W8kQB4D~pEtfXtjRrR2asC~-~cGvivFM5nbT9isqOBoVTnI6Ko3qgxRJilysXPXI0rQ3kOFNDNe7nYzNYmRP7aQlwvshCkyOJm5jwF0Rx-mbJCmreX-pnM7UpJPo0Q__'} alt='loading' /> */}
        </div>
    )
}