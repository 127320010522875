import { ErrorMessage } from "@hookform/error-message";
import FormComponents from "../FormComponents";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Alert from "../Alert";
import { Collumn, Row } from "../Layouts";
import styles from './index.module.css'
import { colors } from "../../utils/colors";
import { Loading } from "../Loading";
import { getForm, submitForm } from "../../services/form/formApi";

const ShortForm: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>([])
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leaveAlertIsOpen, setLeaveAlertIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true)
      await getForm('short-form').then((response) => {
        setFormData(response)
        setLoading(false)
      })
    })()
  }, [])

  const methods = useForm<any>();
  const {
    handleSubmit,
    formState: { errors },
    getValues
  } = methods;
  console.log({ errors })

  const onSubmit = async (data: any) => {
    const transformedArray = Object.entries(data).map(([key, value]) => ({
      code: key,
      value: value
    }));
    setLoading(true)
    const submitResponse = await submitForm(
      {
        name: 'short-form',
        isValidated: false,
        fields: transformedArray
      }
    )
    if (submitResponse) {
      setLoading(false)
      setOpen(true);
    }
  };

  return loading ? <Loading /> : (
    <>
      <Collumn style={{ alignItems: 'center' }}>
        <span className={styles.stepTitle}>
          Contact
        </span>
        <span className={styles.stepSubtitle}>
          Fill in your details and our team will contact you.
        </span>
      </Collumn>
      <FormProvider {...methods}>
        <form style={{ width: 743, height: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <Row style={{ gap: 22, width: 660, flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {formData?.steps && formData.steps[0].fields.map((field: any, index: number) => {
              const widthFormatted = field?.size ? Number(field.size.replace("%", "")) : 100;
              const width = widthFormatted === 100 ? widthFormatted : widthFormatted - 2;
              console.log(width)
              return (
                <Collumn
                  key={field.code}
                  style={{
                    width: `${width}%`
                  }}
                >
                  <label
                    style={{
                      color: "#161616",
                      fontFamily: "InterSemiBold",
                      fontSize: 14,
                      marginBottom: 9,
                    }}
                  >
                    {field?.title ?? ''}
                    {field?.title && field.required && <span style={{ color: colors.primaryOrange }}>*</span>}
                  </label>
                  {field?.subTitle && <span
                    style={{
                      color: "#161616",
                      fontFamily: "InterSemiBold",
                      fontSize: 12,
                      marginBottom: 9,
                    }}
                  >
                    {field.subTitle}
                  </span>}
                  <FormComponents field={field} />
                  <ErrorMessage
                    errors={errors}
                    name={field.code}
                    render={({ message }) => message && <span className={styles.error}>{message}</span>}
                  />
                </Collumn>
              )
            })}
          </Row>
          <Row
            style={{
              gap: 20,
              marginTop: 30,
              width: 743,
              justifyContent: "center",
            }}
          >
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setLeaveAlertIsOpen(true);
              }}
              style={{
                width: 200,
                height: 44,
                borderRadius: 8,
                background: "#EBEBEB",
                border: "none",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  color: "#161616",
                  fontFamily: "InterBold",
                  fontSize: 14,
                }}
              >
                Back
              </span>
            </button>

            <button
              type="submit"
              style={{
                width: 200,
                height: 44,
                borderRadius: 8,
                background: colors.primaryOrange,
                border: "none",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  color: "white",
                  fontFamily: "InterBold",
                  fontSize: 14,
                }}
              >
                Save
              </span>
            </button>
          </Row>

        </form>
      </FormProvider>

      <Alert
        message="Entraremos em contato assim que possível. Obrigado por aplicar!"
        title="Formulário enviado com sucesso!"
        onClose={() => {
          setOpen(false);
          navigate("/")
        }}
        approveText="INÍCIO"
        open={open}
      />
      <Alert
        title="Sair do Formulário"
        message="Você tem certeza que deseja sair do formulário agora? Todas as suas informações serão perdidas."
        onApprove={() => localStorage.getItem("lang") === 'en' ? navigate("/become-an-agent") : navigate("/torne-se-agente")}
        open={leaveAlertIsOpen}
        onClose={() => setLeaveAlertIsOpen(false)}
        approveText="Sair"
        rejectText="Voltar ao Formulário"
      />
    </>
  )
};

export default ShortForm;