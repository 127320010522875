"use client";
import { CenteredDiv, Collumn, Link, Row } from "../../components/Layouts";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./index.module.css";
import foto from "../../assets/fillFullForm.png";
import { MdTaskAlt } from "react-icons/md";
import { colors } from "../../utils/colors";
import { useState } from "react";
import { Checkbox } from "@mui/material";
import { IoArrowForward } from "react-icons/io5";

const FullFormCompleted = () => {

  const [submitted, setSubmitted] = useState(false)

  return (
    <div>
      <Header modeDark isAuth />

      <Row className={styles.content} >
        <Collumn
          style={{
            justifyContent: "center",
            gap: 22,
            paddingRight: 30,
            width: '45vw'
          }}
        >
          {submitted ? (
            <>
              <MdTaskAlt color={colors.primaryOrange} size={60} />
              <span className={styles.title}>
                Form submitted successfully!
              </span>
              <span className={styles.subTitleOrange}>
                We will contact you regarding your application shortly.
              </span>
              <Link href="/user-progress">
                <CenteredDiv className={styles.applyButton}>
                  <span className={styles.applyButtonText}>See your application progress!</span>
                  <IoArrowForward
                    size={24}
                    color="white"
                    style={{ marginLeft: 12 }}
                  />
                </CenteredDiv>
              </Link>
            </>
          ) : (
            <>
              <span className={styles.title}>
                We're almost done!
              </span>
              <Row>
                <Checkbox style={{ color: '#ea7100' }} />
                <Collumn>
                  <span className={styles.subTitleBold}>Loren Ipsum</span>
                  <span className={styles.subTitleBlack}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.</span>
                </Collumn>
              </Row>
              <CenteredDiv className={styles.applyButton} onClick={() => setSubmitted(true)}>
                <span className={styles.applyButtonText}>Submit</span>
                <IoArrowForward
                  size={24}
                  color="white"
                  style={{ marginLeft: 12 }}
                />
              </CenteredDiv>
            </>
          )}
        </Collumn>

        <div className={styles.image} style={{
          background: `url(${foto}) 0%/ cover no-repeat`,
          width: '60%',
          height: '100%'
        }}
        />

      </Row>

      <Footer />
    </div>
  );
};

export default FullFormCompleted;
