"use client";
import Footer from "../../components/Footer";
import carousel02 from "../../assets/carousel02.jpeg";
import becomeAnAgentCouple from "../../assets/becomeAnAgentCouple.jpg";
import { CenteredDiv, Link, Row } from "../../components/Layouts";
import Header from "../../components/Header";
import { IoArrowForward } from "react-icons/io5";
import { colors } from "../../utils/colors";
import BoxLogoHorizontal from "../../components/BoxLogoHorizontal";
import styles from "./index.module.css";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import useWindowDimensions from "../../utils/layout";
import useScrollSnap from "react-use-scroll-snap";

import phone from "../../assets/phone.svg";
import globe from "../../assets/globe.svg";
import tag from "../../assets/tag.svg";
import exchange from "../../assets/exchange.svg";

const BecomeAnAgent = () => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  const scrollRef = useRef(null);
  useScrollSnap({ ref: scrollRef, duration: 300, });

  return (
    <div ref={scrollRef} style={{ flex: 1 }}>
      <Header hasBackground />

      <CenteredDiv
        style={{
          flexDirection: "column",
          minHeight: "100vh",
          background: width > 550 ? `url(${carousel02}) lightgray -569.044px -187.178px / 142.221% 135.771% no-repeat`
            : `url(${carousel02}) lightgray -1450px -100px / 480% 128% no-repeat`,
          gap: 48,
        }}
      >
        <span
          style={{
            color: "white",
            fontFamily: "CitrinaBold",
            fontSize: width < 550 ? 32 : 48,
            lineHeight: "118%",
            textAlign: "center",
          }}
        >
          {t('BecomeAnAgent.Section1.TitleFirstLine')}
          <br />
          {t('BecomeAnAgent.Section1.TitleSecondLine')}
        </span>

        <Link href={"/short-form"}>
          <Row
            style={{
              cursor: "pointer",
            }}
          >
            <span
              style={{
                fontFamily: "InterBold",
                fontSize: width < 550 ? 15 : 18,
                lineHeight: "150%",
                color: "white",
                marginRight: 8,
              }}
            >
              {t('BecomeAnAgent.Section1.ApplyButton')}
            </span>
            <IoArrowForward size={24} color="white" />
          </Row>
        </Link>
      </CenteredDiv>

      <CenteredDiv
        style={{
          padding: width < 550 ? "80px 50px 20px 50px" : "96px 156px",
          boxSizing: 'border-box',
          minHeight: "100vh",
          flexDirection: "column",
          backgroundColor: "white",
        }}
      >
        <span
          id="whitediv"
          style={{
            fontFamily: "CitrinaBold",
            fontSize: width < 550 ? 28 : 48,
            color: colors.neutralGray04,
            marginBottom: 22,
            textAlign: "center",
          }}
        >
          {t('BecomeAnAgent.Section2.TitleFirstLine')}
          <br />
          {t('BecomeAnAgent.Section2.TitleSecondLine')}
        </span>
        <div className={styles.containerLeaderSolution}>
          <BoxLogoHorizontal
            Icon={<img src={phone} alt="phone" />}
            title={t('BecomeAnAgent.Section2.Card1.Title')}
          />
          <BoxLogoHorizontal
            Icon={<img src={exchange} alt="exchange" />}
            title={t('BecomeAnAgent.Section2.Card2.Title')}
          />
          <BoxLogoHorizontal
            Icon={<img src={tag} alt="tag" />}
            title={t('BecomeAnAgent.Section2.Card3.Title')}
          />
          <BoxLogoHorizontal
            Icon={<img src={globe} alt="globe" />}
            title={t('BecomeAnAgent.Section2.Card4.Title')}
          />
        </div>
        {width > 550 && <Link href="/short-form">
          <CenteredDiv className={styles.applyButton}>
            <span className={styles.applyButtonText}>{t('BecomeAnAgent.Section2.ApplyButton')}</span>
            <IoArrowForward
              size={24}
              color="white"
              style={{ marginLeft: 12 }}
            />
          </CenteredDiv>
        </Link>}
      </CenteredDiv>

      <CenteredDiv
        style={{
          flexDirection: "column",
          minHeight: "100vh",
          background: `url(${becomeAnAgentCouple}) lightgray 50% / cover no-repeat`,
          gap: 48,
          padding: width < 550 ? "50px" : "70px 50px",
        }}
      >
        <span
          style={{
            fontFamily: "CitrinaBold",
            fontSize: width < 550 ? 32 : 48,
            textAlign: "center",
            color: "white",
          }}
        >
          {t('BecomeAnAgent.Section3.TitleFirstLine')}
          <br />
          {t('BecomeAnAgent.Section3.TitleSecondLine')}
        </span>
        {width < 550 && (
          <CenteredDiv className={styles.applyButton}>
            <span className={styles.applyButtonText}>{t('BecomeAnAgent.Section2.ApplyButton')}</span>
            <IoArrowForward
              size={24}
              color="white"
              style={{ marginLeft: 12 }}
            />
          </CenteredDiv>
        )}
      </CenteredDiv>

      <Footer />
    </div>
  );
};

export default BecomeAnAgent;
