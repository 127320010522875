import { CenteredDiv, Collumn, Row } from "../../components/Layouts";
import logo from "../../assets/logo.svg";
import styles from "./index.module.css";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IoArrowForward,
  IoCheckmarkCircleOutline,
  IoCloseOutline,
  IoEye,
  IoEyeOff,
} from "react-icons/io5";
import React, { useEffect, useState } from "react";
import { colors } from "../../utils/colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import useWindowDimensions from "../../utils/layout";
import carousel01 from "../../assets/carousel01.jpg";
import { emailEncrypted } from "../../services/auth/authApi";
import { updateUser } from "../../services/user/userApi";

interface FormData {
  password: string;
  confirmPassword: string;
}

const RecoverPassword: React.FC = () => {
  const { width } = useWindowDimensions()
  const navigate = useNavigate();
  const [greenBoxOpen, setGreenBoxOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [seePassword, setSeePassword] = useState(false);
  const [seeConfirmPassword, setSeeConfirmPassword] = useState(false);
  const [params] = useSearchParams()
  const [userId, setUserId] = useState()
  const [userEmail, setUserEmail] = useState()


  const schema: any = yup.object().shape({
    password: yup
      .string()
      .required("Digite uma senha válida")
      .min(6, "senha deve ter 6 caracteres")
      .matches(/[A-Z]/, "letra maiúscula faltante")
      .matches(/[a-z]/, "letra minúscula faltante")
      .matches(/[0-9]/, "número faltante"),
    // .matches(/[!@#$%^&*(),.?":{}|<>]/, "caracter especial faltante"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "senhas devem ser iguais")
      .required("Digite uma senha válida"),
  });

  const methods = useForm<FormData>({
    resolver: yupResolver(
      !isSubmitted
        ? schema
        : yup.object().shape({
          password: yup
            .string()
            .required("Digite uma senha válida")
            .min(6, "senha deve ter 6 caracteres")
            .matches(/[A-Z]/, "letra maiúscula faltante")
            .matches(/[a-z]/, "letra minúscula faltante")
            .matches(/[0-9]/, "número faltante"),
        })
    ),
    mode: "onBlur",
  });

  const email = params.get('email') ?? ''
  const phone = params.get('phone') ?? ''
  const token = params.get('token') ?? ''
  const formattedEmail = email?.replaceAll(' ', '+')

  useEffect(() => {
    (async () => {

      console.log({
        phone, email, token
      })
      await emailEncrypted(
        {
          recipient: formattedEmail,
          encryptedEmail: true,
          resetToken: token,
        }).then((data: any) => {
          if (data?.id) setUserId(data.id)
          if (data?.email) setUserEmail(data.email)
        })
    })()
  }, [])


  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = methods;

  const onSubmit: SubmitHandler<FormData> = async (data: any) => {
    setGreenBoxOpen(true);
    await updateUser({
      email: 'robson.junior@luby.com.br',
      password: data.password,
      id: '8bade027-05fd-4aa4-9f3f-e230665ef1d0'
    })

    setValue("password", "");
    if (!isSubmitted) setIsSubmitted(true);
    else {
      navigate("/login");
    }
  };

  const Form = (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      {greenBoxOpen && (
        <Row className={styles.greenBox}>
          <IoCheckmarkCircleOutline size={24} color="#008145" />
          <span>
            Sua senha foi recuperada com sucesso. Agora você já pode fazer o
            login na sua conta!
          </span>
          <IoCloseOutline
            size={24}
            color="#3E322E"
            onClick={() => setGreenBoxOpen(false)}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
            }}
          />
        </Row>
      )}
      {!isSubmitted && (
        <>
          <Collumn style={{ position: "relative" }}>
            <label className={styles.label}>Nova Senha</label>
            <input
              className={styles.input}
              type={seePassword ? "" : "password"}
              {...register("password")}
            />
            <p className={styles.error}>{errors.password?.message ?? ""}</p>
            {seePassword ? (
              <IoEye
                onClick={() => setSeePassword(false)}
                color={colors.primaryOrange}
                size={26}
                style={{
                  position: "absolute",
                  top: 42,
                  right: 10,
                  cursor: "pointer",
                }}
              />
            ) : (
              <IoEyeOff
                size={26}
                onClick={() => setSeePassword(true)}
                color={colors.primaryOrange}
                style={{
                  position: "absolute",
                  top: 42,
                  right: 10,
                  cursor: "pointer",
                }}
              />
            )}
          </Collumn>


          <Collumn
            style={{
              marginTop: 22,
              position: "relative",
            }}
          >
            <label className={styles.label}>Confirme a senha</label>
            <input
              className={styles.input}
              type={seeConfirmPassword ? "" : "password"}
              {...register("confirmPassword")}
            />
            <p className={styles.error}>
              {errors.confirmPassword?.message ?? ""}
            </p>
            {seeConfirmPassword ? (
              <IoEye
                onClick={() => setSeeConfirmPassword(false)}
                color={colors.primaryOrange}
                size={26}
                style={{
                  position: "absolute",
                  top: 42,
                  right: 10,
                  cursor: "pointer",
                }}
              />
            ) : (
              <IoEyeOff
                size={26}
                onClick={() => setSeeConfirmPassword(true)}
                color={colors.primaryOrange}
                style={{
                  position: "absolute",
                  top: 42,
                  right: 10,
                  cursor: "pointer",
                }}
              />
            )}
          </Collumn>
        </>
      )}

      {!isSubmitted && (
        <Collumn>
          <span className={styles.passwordRules}>
            Sua senha deve conter:{" "}
            <li>Ao menos uma letra maíuscula e minúscula;</li>
            <li>Ao menos um número;</li>
            <li>Mínimo de 6 caracteres.</li>
          </span>
        </Collumn>
      )}

      <CenteredDiv style={{ marginTop: 32, flexDirection: "column" }}>
        <button type="submit" className={styles.continueButton} onClick={() => {
          if (isSubmitted) {
            navigate('/login')
          }
        }}>
          <CenteredDiv>
            {!isSubmitted ? "Recuperar sua senha" : "Login"}
            <IoArrowForward size={20} style={{ marginLeft: 8 }} />
          </CenteredDiv>
        </button>
      </CenteredDiv>
    </form>
  )

  return width >= 900 ? (
    <Row style={{ flex: 1 }}>
      <div
        style={{
          width: "50%",
          height: "100vh",
          background: `url(${carousel01}) lightgray 0px 0px / 100% 100% no-repeat`
        }}
      />
      <CenteredDiv
        style={{
          width: "50%",
          gap: 32,
          flexDirection: "column",
          padding: "0px 52px",
          boxSizing: "border-box",
        }}
      >
        <img src={logo} alt="logo" width={300} />
        <span className={styles.title}>Recuperação de Senha</span>
        {Form}
      </CenteredDiv>
    </Row>
  ) : (
    <CenteredDiv
      style={{
        minHeight: '100vh',
        background: `url(${carousel01}) 50% / cover no-repeat`,
        padding: 20
      }}>
      <CenteredDiv
        style={{
          width: "100%",
          gap: 32,
          flexDirection: "column",
          padding: 32,
          borderRadius: 8,
          background: 'white',
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.09)'
        }}
      >
        <img src={logo} alt="logo" width={width < 500 ? 250 : 300} />
        <span className={styles.title} style={{
          fontSize: width <= 550 ? 28 : 48
        }}>Recuperação de Senha</span>
        {Form}
      </CenteredDiv>
    </CenteredDiv>
  );
};

export default RecoverPassword;
