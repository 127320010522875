import React, { forwardRef } from "react";

interface LayoutProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  ref?: any;
  onClick?: () => void;
  key?: number
  id?: string
}

export const Row: React.FC<LayoutProps> = ({ children, style, className, key, onClick }) => (
  <div onClick={onClick} key={key} style={{ ...style, display: "flex" }} className={className}>
    {children}
  </div>
);

export const Collumn: React.FC<LayoutProps> = forwardRef(
  ({ children, style, className, key, id }, ref: any) => (
    <div
      id={id}
      style={{ ...style, display: "flex", flexDirection: "column" }}
      className={className}
      ref={ref}
      key={key}
    >
      {children}
    </div>
  )
);

export const CenteredDiv: React.FC<LayoutProps> = ({
  children,
  style,
  className,
  onClick,
  key,
  id
}) => (
  <div
    id={id}
    key={key}
    onClick={onClick}
    style={{
      ...style,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    className={className}
  >
    {children}
  </div>
);

export const Link: React.FC<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
> = ({ children, href }) => (
  <a href={href} style={{ color: "inherit", textDecoration: "none" }}>
    {children}
  </a>
);
