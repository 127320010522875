import { apiClient } from "../apiClients"

export const getAllForms = async () => {
    return apiClient
        .get('/form')
        .then(response => response.data)
        .catch(e => console.log({ e }))
}

export const getForm = async (formCode: string) => {
    return apiClient.get(`/form/${formCode}`)
        .then(response => response.data)
        .catch((e) => console.log(e))
}

export const setForm = async (body: any) => {
    return apiClient.post('/form', body)
        .then((response) => response.data)
        .catch((e) => console.log(e))
}

export const updateForm = async (body: any) => {
    return apiClient.put('/form/', body)
        .then((response) => response.data)
        .catch((e) => console.log(e))
}

export const deleteForm = async (formId: string) => {
    return apiClient.delete(`/form/${formId}`)
        .then((result) => result.data)
        .catch((e) => console.log(e))
}

export const submitForm = async (body: any) => {
    return apiClient.post(`/submission`, body)
        .then((result) => result)
        .catch((e) => console.log(e))
}
