"use client";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Row } from "../Layouts";
import styles from "./index.module.css";
import { colors } from "../../utils/colors";
import Alert from "../Alert";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import RegisterFormStep1 from "../RegisterFormStep1";
import RegisterFormStep2 from "../RegisterFormStep2";
import AlertConfirmationCode from "../AlertConfirmationCode";
import { MdRefresh } from "react-icons/md";
import useWindowDimensions from "../../utils/layout";
import { Loading } from "../Loading";
import { emailEncrypted } from "../../services/auth/authApi";
import { updateUser } from "../../services/user/userApi";

interface IRegisterForm {
  step: number;
  setStep: any;
}

export interface IRegisterFormData {
  name: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  lgpdAuthorized: boolean;
}

const RegisterForm: React.FC<IRegisterForm> = ({ step, setStep }) => {
  const navigate = useNavigate();
  const [leaveAlertIsOpen, setLeaveAlertIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<any>();
  const [confirmationAlert, setConfirmationAlert] = useState(false);
  const [loading, setLoading] = useState(false)
  const [params] = useSearchParams()
  const [userId, setUserId] = useState()
  const [userEmail, setUserEmail] = useState()
  const { width } = useWindowDimensions()

  useEffect(() => {
    (async () => {
      const email = params.get('email') ?? ''
      const token = params.get('token') ?? ''

      const formattedEmail = email?.replaceAll(' ', '+')
      setLoading(true)
      await emailEncrypted(
        {
          recipient: formattedEmail,
          encryptedEmail: true,
          resetToken: token
        }).then((data: any) => {
          setLoading(false)
          setUser(data)
          setValue('name', data?.name)
          setValue('email', data?.email)
          setValue('phone', data?.phone)
          setUserId(data?.id)
          setUserEmail(data?.email)
        })
    })()
  }, [params])

  const schemaStep1: any = yup.object().shape({
    name: yup.string().required("invalid field"),
    email: yup.string().email("invalid format").required("invalid field"),
    phone: yup.string().required("invalid field"),
    lgpdAuthorized: yup.boolean().oneOf([true], "Please, accept the terms"),
  });

  const schemaStep2: any = yup.object().shape({
    password: yup
      .string()
      .required("Digite uma senha válida")
      .min(6, "senha deve ter 6 caracteres")
      .matches(/[A-Z]/, "letra maiúscula faltante")
      .matches(/[a-z]/, "letra minúscula faltante")
      .matches(/[0-9]/, "número faltante"),
    // .matches(/[!@#$%^&*(),.?":{}|<>]/, "caracter especial faltante"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Senhas não coincidem. Tente novamente")
      .required("Digite uma senha válida"),
  });

  const methods = useForm<IRegisterFormData>({
    resolver: yupResolver(step === 1 ? schemaStep1 : schemaStep2),
    mode: "onChange",
    defaultValues: {
      lgpdAuthorized: false,
      name: user?.name ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
    },
  });

  const { handleSubmit, watch, setValue } = methods;
  const isDisabled = step === 1 ? (watch("lgpdAuthorized") === false || loading) : false;

  useEffect(() => {
    setValue('lgpdAuthorized', false)
  }, [])

  const onSubmit: SubmitHandler<IRegisterFormData> = async (data: any) => {
    setLoading(true)
    const resp = await updateUser(step === 1 ? {
      name: data.name,
      email: data.email,
      phone: Number(data.phone),
      lgpdAuthorized: data.lgpdAuthorized,
      id: userId
    } : {
      email: userEmail,
      password: data.password,
      id: userId
    })
    if (resp) {
      setLoading(false)
      if (step === 1) {
        setStep((prev: number) => prev + 1);
      } else {
        // setConfirmationAlert(true);
        navigate('/register/success')
      }
    }
  };

  return loading ? <Loading /> : (
    <>
      <span className={styles.stepTitle} style={{
        fontSize: width > 550 ? 48 : 32
      }}>Registration</span >
      <FormProvider {...methods}>
        <form style={{
          maxWidth: 743,
          paddingBottom: 40,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {step === 1 && <RegisterFormStep1 />}
          {step === 2 && <RegisterFormStep2 />}
          <Row
            style={{
              gap: 20,
              justifyContent: "center",
            }}
          >
            {step === 2 &&
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (step >= 2) setStep((prev: number) => prev - 1);
                  else {
                    setLeaveAlertIsOpen(true);
                  }
                }}
                style={{
                  width: 200,
                  height: 44,
                  borderRadius: 8,
                  background: "#EBEBEB",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    color: "#161616",
                    fontFamily: "InterBold",
                    fontSize: 14,
                  }}
                >
                  Back
                </span>
              </button>}

            <button
              type="submit"
              disabled={isDisabled}
              style={{
                width: 200,
                height: 44,
                borderRadius: 8,
                background: isDisabled ? "#DEDFE4" : colors.primaryOrange,
                border: "none",
                cursor: isDisabled ? "" : "pointer",
              }}
            >
              <span
                style={{
                  color: "white",
                  fontFamily: "InterBold",
                  fontSize: 14,
                }}
              >
                {step === 1 && !loading ? "Next" : "Submit"}
                {loading && <MdRefresh />}
              </span>
            </button>
          </Row>
        </form>
      </FormProvider >
      <Alert
        message="Você já pode entrar na sua conta."
        title="Cadastro concluído com sucesso!"
        onClose={() => {
          setOpen(false);
          navigate("/login");
        }}
        approveText="LOGIN"
        open={open}
      />
      <Alert
        title="Sair do Formulário"
        message="Você tem certeza que deseja sair do formulário agora? Todas as suas informações serão perdidas."
        onApprove={() => localStorage.getItem("lang") === 'en' ? navigate("/become-an-agent") : navigate("/torne-se-agente")}
        open={leaveAlertIsOpen}
        onClose={() => setLeaveAlertIsOpen(false)}
        approveText="Sair"
        rejectText="Voltar ao Formulário"
      />
      <AlertConfirmationCode
        title="Confirmação"
        message="Um código de confirmação foi enviado para o seu e-mail"
        onClose={() => setConfirmationAlert(false)}
        rejectText="REENVIAR CÓDIGO"
        approveText="CONFIRMAR"
        onApprove={() => {
          setConfirmationAlert(false);
          setOpen(true);
        }}
        open={confirmationAlert}
      />
    </>
  );
};

export default RegisterForm;
