import HeaderForms from "../../components/HeaderForms";
import { Collumn, Row } from "../../components/Layouts";
import UserProgressSteps from "../../components/UserProgressSteps";
import styles from './index.module.css'

const UserProgress = () => {

    return (
        <div style={{ minHeight: '100vh', width: '100vw' }}>
            <HeaderForms showOptions />
            <Row style={{ height: '100%', border: '1px solid' }}>
                <Collumn className={styles.sideDiv}>
                    <div className={styles.lettersContainer}>
                        <span className={styles.letters}>LW</span>
                    </div>
                    <span className={styles.nameTitle}>Linda Watson</span>
                    <span className={styles.nameSubtitle}>www.lindasalloonbr.com</span>
                    <span className={styles.nameSubtitle}>Signed up at Feb 15, 2024</span>
                    <span className={styles.timePeriodText}>Time Period</span>
                    <div className={styles.grayBox}>
                        <span>Jan 31 - 31 Mar 2024</span>
                    </div>
                </Collumn>
                <Collumn style={{ paddingTop: 105, paddingLeft: 43 }}>
                    <span className={styles.title}>Your Application</span>
                    <span className={styles.subtitle}>Overview</span>

                    <UserProgressSteps />

                    <span className={styles.title}>Alerts</span>
                    <Collumn className={styles.alertsDiv}>
                        <span className={styles.alertsTitle}>You're about to become an Inter agent!</span>
                        <span className={styles.alertsSubtitle}>Your form has been sent successfully! Follow the progress on the timeline.</span>
                    </Collumn>
                    {/* <span className={styles.title}>MA Documents</span> */}

                </Collumn>
            </Row>
        </div >
    )
}

export default UserProgress;