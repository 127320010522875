import { Dispatch, SetStateAction } from "react";
import FullFormBottomButtons from "../FullFormBottomButtons";

interface FullFormContainerProps {
  step: number,
  setStep: Dispatch<SetStateAction<number>>,
  children: React.ReactNode,
  onNext?: () => void
  onGoBack?: () => void
}

const FullFormContainer: React.FC<FullFormContainerProps> = ({ step, setStep, children, onNext, onGoBack }) => {
  return (
    <div>
      {children}
      <FullFormBottomButtons step={step} setStep={setStep} onNext={onNext} onGoBack={onGoBack} />
    </div>
  );
};

export default FullFormContainer;
