import { apiClient } from "../apiClients"

export const getUserByEmail = async (email: string) => {
    return apiClient.get(`/user/email/` + email)
        .then((result) => result.data)
        .catch((e) => console.log(e))
}

export const getUserByPhone = async (phone: string) => {
    return apiClient.get(`/user/phone/` + phone)
        .then((result) => result.data)
        .catch((e) => console.log(e))
}

export const updateUser = async (body: any) => {
    return apiClient.put(`/user`, body)
        .then((result) => result.data)
        .catch((e) => console.log(e))
}

export const resetPassword = async (body: any) => {
    return apiClient.post('/authorization/send-reset-token-email', body)
        .then((result) => result.data)
        .catch((e) => console.log(e))
}