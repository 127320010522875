import React from "react";
import { CenteredDiv, Collumn, Row } from "../Layouts";
import { colors } from "../../utils/colors";
import { MdCheck } from "react-icons/md";
import useWindowDimensions from "../../utils/layout";
import { MenuItem, Select } from "@mui/material";
import { GrDown } from "react-icons/gr";

interface ISteps {
  step: number;
}

const Steps: React.FC<ISteps> = ({ step }) => {
  const { width } = useWindowDimensions()
  return (
    <Collumn
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 66,
      }}
    >
      {width > 720 ?
        <>
          <Row>
            {step === 1 ? (
              <div
                style={{
                  width: 13,
                  height: 13,
                  backgroundColor: "#EEEEF5",
                  borderRadius: 30,
                  border:
                    step === 1
                      ? `4px solid ${colors.primaryOrange}`
                      : "4px solid #EEEEF5",
                }}
              />
            ) : (
              <CenteredDiv
                style={{
                  width: 21,
                  height: 21,
                  borderRadius: 30,
                  backgroundColor: colors.primaryOrange,
                }}
              >
                <MdCheck color={"white"} />
              </CenteredDiv>
            )}

            <div style={{ height: 21 }}>
              <div
                style={{
                  width: 403,
                  backgroundColor: step >= 2 ? colors.primaryOrange : "#EEEEF5",
                  height: 4,
                  marginTop: 8.5,
                }}
              />
            </div>

            {step === 1 && (
              <div
                style={{
                  width: 21,
                  height: 21,
                  backgroundColor: "#EEEEF5",
                  borderRadius: 30,
                }}
              />
            )}

            {step === 2 && (
              <div
                style={{
                  width: 13,
                  height: 13,
                  backgroundColor: "#EEEEF5",
                  borderRadius: 30,
                  border: `4px solid ${colors.primaryOrange}`,
                }}
              />
            )}

            {step === 3 && (
              <CenteredDiv
                style={{
                  width: 21,
                  height: 21,
                  borderRadius: 30,
                  backgroundColor: colors.primaryOrange,
                }}
              >
                <MdCheck color={"white"} />
              </CenteredDiv>
            )}
          </Row>

          <Row
            style={{
              justifyContent: "space-between",
              width: 530,
              paddingRight: 40,
              paddingLeft: 0,
              marginTop: 8,
            }}
          >
            <span
              style={{
                color: "#1c1c1c",
                fontFamily: "InterSemiBold",
                fontSize: 14,
              }}
            >
              Informações Pessoais
            </span>
            <span
              style={{
                color: step >= 2 ? "#1c1c1c" : "#9C9DA7",
                fontFamily: "InterSemiBold",
                fontSize: 14,
              }}
            >
              Credenciais
            </span>
          </Row>
        </>
        : (
          <>
            <Select
              defaultValue={'step1'}
              style={{ boxSizing: 'content-box', fontSize: 14, color: '#161616', fontFamily: 'InterSemiBold', width: '80vw' }}
              IconComponent={() => step === 2 ? (
                <GrDown size={40} style={{ marginRight: 10 }} color="#ea7100" />
              ) : null}
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #ea7100',
                },
                '&.MuiInputBase-root': {
                  border: '1px solid #ebebeb',
                },
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                }
              }}>
              <MenuItem value={'step1'}>
                <Row style={{ alignItems: 'center', gap: 22 }}>
                  {step === 2 && (
                    <CenteredDiv style={{ width: 40, height: 40, borderRadius: 50, backgroundColor: '#ea7100' }}>
                      <MdCheck size={24} color="white" />
                    </CenteredDiv>
                  )}
                  <span style={{ color: '#161616', fontFamily: 'InterSemiBold', fontSize: 14 }}>
                    Credenciais
                  </span>
                </Row>
              </MenuItem>
              {step === 2 && <MenuItem value={'step2'}>
                <Row>
                  Informações Pessoais
                </Row></MenuItem>}
            </Select >
          </>
        )}
    </Collumn>
  );
};

export default Steps;
