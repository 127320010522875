import { useFormContext } from "react-hook-form";
import { FormDataFullForm } from "../../pages/FullForm";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { colors } from "../../utils/colors";
import styles from './index.module.css'

interface DateInputProps {
    registerName: any,
    isRequired?: boolean
}

const DateInput: React.FC<DateInputProps> = ({ registerName, isRequired }) => {
    const {
        setValue,
    } = useFormContext<FormDataFullForm>();

    const [date, setDate] = useState()

    return (
        <div className={styles.dateContainer}>
            <span className={styles.dateInput}>
                <DatePicker
                    selected={date}
                    onChange={(date: any) => {
                        setDate(date)
                        setValue(registerName, date)
                    }}
                    todayButton="TODAY"
                    placeholderText="dd/mm/yyyy"
                />
            </span>
            <span className={styles.icon}>
                <MdOutlineCalendarMonth color={colors.primaryOrange} size={24} />
            </span>
        </div>
    )
}

export default DateInput