import { apiClient } from "../apiClients"

export const getAllSteps = async () => {
    return apiClient.get(`/form/step`)
        .then((result) => result.data)
        .catch((e) => console.log(e))
}

export const getStep = async (stepCode: string) => {
    return apiClient.get(`/form/step/${stepCode}`)
        .then((result) => result.data)
        .catch((e) => console.log(e))
}

export const setStep = async (body: any) => {
    return apiClient.post(`/form/step/`, body)
        .then((result) => result.data)
        .catch((e) => console.log(e))
}

export const updateStep = async (body: any) => {
    return apiClient.put(`/form/step/`, body)
        .then((result) => result.data)
        .catch((e) => console.log(e))
}

export const deleteStep = async (stepId: string) => {
    return apiClient.delete(`/form/step/${stepId}`)
        .then((result) => result.data)
        .catch((e) => console.log(e))
}