import { CenteredDiv, Collumn, Link, Row } from "../../components/Layouts";
import logo from "../../assets/logo.svg";
import styles from "./index.module.css";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IoArrowForward,
  IoCheckmarkCircleOutline,
  IoCloseOutline,
} from "react-icons/io5";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../utils/layout";
import carousel01 from "../../assets/carousel01.jpg";
import AlertConfirmationCode from "../../components/AlertConfirmationCode";
import { colors } from "../../utils/colors";
import { getUserByPhone, resetPassword } from "../../services/user/userApi";
import { sendSms } from "../../services/auth/authApi";

interface FormData {
  email?: string;
  phone?: string;
}

const ForgotPassword = () => {
  const { width } = useWindowDimensions()
  const navigate = useNavigate();
  const [greenBoxOpen, setGreenBoxOpen] = useState(false);
  const [recoverBySMS, setRecoverBySMS] = useState(false);
  const [confirmationAlert, setConfirmationAlert] = useState(false);
  const [userPhone, setUserPhone] = useState('');
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const schema: any = yup.object().shape({
    email: yup.string().email("formato inválido"),
    phone: yup.string(),
  });

  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const { handleSubmit, register, setValue } = methods;

  const onSubmit: SubmitHandler<FormData> = async (data: any) => {
    if (!recoverBySMS) {
      await resetPassword({
        subject: "Reset de Senha InterAgents",
        recipient: data.email,
        cc: [
          "jreisam123@gmail.com"
        ],
        recoverPassword: "true",
        encryptedEmail: "true"
      }).then((resp) => {
        if (resp)
          setGreenBoxOpen(true);
      })
    } else {
      setUserPhone(data.phone)
      await sendSms({
        cellphoneNumber: Number(data.phone)
      }).then((resp) => {
        if (resp)
          setConfirmationAlert(true)
      }).catch((err) => {
        console.log(err)
      })
    }
  };

  const handleClick = () => {
    setValue('email', '')
    setValue('phone', '')
    setRecoverBySMS(prev => !prev)
  }

  const findUserByPhone = async () => {
    await getUserByPhone(userPhone).then((data) => {
      setUserId(data.id)
      setUserEmail(data.id)
    })
  }

  const Form = (
    <>
      <form style={{ width: '80%' }} onSubmit={handleSubmit(onSubmit)}>
        {greenBoxOpen && (
          <Row className={styles.greenBox}>
            <IoCheckmarkCircleOutline size={24} color="#008145" />
            <span>
              Um link para recuperar sua senha foi enviado para o seu {recoverBySMS ? 'celular' : 'e-mail'}.
              Confira!{" "}
            </span>
            <IoCloseOutline
              size={24}
              color="#3E322E"
              onClick={() => setGreenBoxOpen(false)}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer",
              }}
            />
          </Row>
        )}
        {!recoverBySMS ? <Collumn>
          <label className={styles.label}>Insira seu e-mail</label>
          <input className={styles.input} {...register("email")} />
        </Collumn> : <Collumn>
          <label className={styles.label}>Insira seu celular</label>
          <input className={styles.input} {...register("phone")} />
        </Collumn>
        }
        <CenteredDiv style={{ marginTop: 32, flexDirection: "column" }}>
          <button type="submit" className={styles.continueButton}>
            <CenteredDiv>
              Recuperar sua senha{" "}
              <IoArrowForward size={20} style={{ marginLeft: 8 }} />
            </CenteredDiv>
          </button>
          <CenteredDiv style={{ marginTop: 12, gap: 32, flexDirection: 'column' }}>
            {!recoverBySMS ? <a onClick={handleClick}>
              <span className={styles.forgotPasswordText} style={{ color: colors.primaryOrange }}>
                Recuperar via SMS
              </span>
            </a> : <a onClick={handleClick}>
              <span className={styles.forgotPasswordText} style={{ color: colors.primaryOrange }}>
                Recuperar via email
              </span>
            </a>}
            <Link href="/login">
              <span className={styles.forgotPasswordText}>
                Voltar para o início
              </span>
            </Link>
          </CenteredDiv>
        </CenteredDiv>
      </form>
      <AlertConfirmationCode
        title="Confirmação"
        message="Um código de confirmação foi enviado para o seu celular"
        onClose={() => setConfirmationAlert(false)}
        rejectText="REENVIAR CÓDIGO"
        approveText="CONFIRMAR"
        onApprove={async (code?: string) => {
          await findUserByPhone()
          await resetPassword({
            subject: "Reset de Senha InterAgents",
            recipient: userEmail,
            cc: [
              "jreisam123@gmail.com"
            ],
            smsToken: code,
            recoverPassword: "true",
            encryptedEmail: "true"
          }).then(() => {
            setConfirmationAlert(false);
            navigate("/recover-password", {
              state: {
                userId
              }
            });
          })
        }}
        onResend={async () => {
          await sendSms({
            cellphoneNumber: Number(userPhone)
          }).then(() => {
            // setGreenBoxOpen(true);
            // navigate("/recover-password");
          })
        }}
        open={confirmationAlert}
      />
    </>

  )

  return width >= 900 ? (
    <Row style={{ flex: 1 }}>
      <div
        style={{
          width: "50%",
          height: "100vh",
          background: `url(${carousel01}) lightgray 0px 0px / 100% 100% no-repeat`
        }}
      />
      <CenteredDiv
        style={{
          width: "50%",
          gap: 32,
          flexDirection: "column",
          padding: "0px 52px",
          boxSizing: "border-box",
        }}
      >
        <img src={logo} alt="logo" width={300} />
        <span className={styles.title}>Esqueceu a sua senha?</span>
        {Form}
      </CenteredDiv>
    </Row>
  ) : (
    <CenteredDiv
      style={{
        minHeight: '100vh',
        background: `url(${carousel01}) 50% / cover no-repeat`,
        padding: 20
      }}>
      <CenteredDiv
        style={{
          width: "100%",
          gap: 32,
          flexDirection: "column",
          padding: 32,
          background: ' white',
          borderRadius: 8,
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.09)'
        }}
      >
        <img src={logo} alt="logo" width={width < 500 ? 250 : 300} />
        <span className={styles.title} style={{
          fontSize: width <= 550 ? 28 : 48
        }}>Esqueceu a sua senha?</span>
        {Form}
      </CenteredDiv>
    </CenteredDiv>
  );
};

export default ForgotPassword;
